import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/taxirio.css';
import TaxirioNavbar from './components/navbar/TaxirioNavbar';

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import Rodape from './components/rodape/Rodape';
import {Home, Login, EnviarFoto, ImportarTaxista} from './paginas';
import { isAuthenticated } from './services/security.js'

function App() {
  const RotaTemaPadrao = ({component: Component, titulo, ...rest }) => (
    <Route
      { ...rest }
      render={props => (
        <>
          {!isAuthenticated() && <Redirect to={"/login"} /> }
          <TaxirioNavbar titulo={titulo}/>
          <Component {...props} />
          <Rodape/>
        </>
      )}
    />
  );
  return (
    <>
      <Router>
        <RotaTemaPadrao exact path="/" component={Home} />
        <RotaTemaPadrao exact path="/enviar-foto-taxista" component={EnviarFoto} />
        <RotaTemaPadrao exact path="/importar-taxista" component={ImportarTaxista} />
        <Route exact path="/login" component={Login} />
      </Router>
    </>
  );
}

export default App;
