import {  IMPORTAR_FOTO_BUSCAR_TAXISTA } from '../actions/actionConstants';

const initialState = {
  enviaFoto: {motoristas: [ { login: '396703',
    city: '5e2b1700fb8eba33461db903',
    user: 
   { 
     fullName: 'MARCIO HENRIQUE GOMES LEAL',
     email: 'hhhh@gmail.com',
     phoneNumber: '21865744858',
     cpf: '03759651739' } } ]}
};
  
export const EnviaFotoReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPORTAR_FOTO_BUSCAR_TAXISTA:
      return {motoristas:action.payload.data};
    default:
      return state;
  }
};