/*
*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  FormGroup, 
  Button,
} from 'reactstrap';

import { reduxForm, Field, formValueSelector } from 'redux-form';

import renderField from '../../components/inputs/renderField';
import renderSelect from '../../components/inputs/renderSelect';
import { required } from '../../components/validadores';

let LoginForm = props => {
  const { handleSubmit, cidades } = props;

  // const cidades = [
  //   {cityId:"60a40d1b3548ebc26551c81b", name:"Maceió"},
  //   {cityId:"608ac4423548ebc2652755e4", name:"Miguel Pereira"}
  // ];
  
  return (
    <form onSubmit={(handleSubmit)}>
      <FormGroup>
        <Field name="cityId" validate={required} label="" placeholder="Cidade" component={renderSelect} type="select" className="form-control">
          <option value={null}>Selecione a cidade</option>
          {cidades.map((c) => (<option key={c.cityId} value={c.cityId}> {c.name} </option>))}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field validate={required} errorLabel="Usuário" placeholder="Usuário" component={renderField} name="usuario" id="usuario" className="form-control"/>
      </FormGroup>
      <FormGroup>
        <Field validate={required} errorLabel="Senha" placeholder="Senha" component={renderField} name="senha" id="senha" type="password" className="form-control"/>
      </FormGroup>
      <Button size='md' id="btn-login" color="warning" type="submit" >Continuar</Button>
    </form>
  );
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

LoginForm = reduxForm({
  // a unique name for the form
  form: 'login'
})(LoginForm)

// Decorate with connect to read form values
const selector = formValueSelector('loginForm') // <-- same as form name
LoginForm = connect(
  state => {
    // can select values individually
    const codigo = selector(state, 'codigo');
    const cpf = selector(state, 'cpf');
    const nome = selector(state, 'nome');
    const {primeiroAcesso} = state;
    return {
      codigo,
      cpf,
      nome,
      primeiroAcesso
    }
  }
)(LoginForm)

export default LoginForm;
