import * as types from './actionConstants';
import apiRequest from '../utils/ApiRequest';

export const loginAction = async (value) => {
  let resultado;
  await apiRequest().post('/authentication/login',value).then( response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  });
  return ({
    type: types.LOGIN,
    payload: resultado
  });
}


export const obterCidadesAction = async () => {
  let resultado;
  
  await apiRequest().post('/cities/getall').then( response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  });

  return ({
    type: types.OBTER_CIDADES,
    payload: resultado
  });
}

