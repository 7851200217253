/*!
*/
import React, {useState} from "react";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import '../../assets/css/menu.css';

const Menu = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <Navbar  className="menu-box" expand="md" light>
        <NavbarToggler onClick={toggle} className="mr-2"/>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Importar
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavLink href="/importar-taxista">Importar taxistas</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink href="/enviar-foto-taxista">Importar Foto</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
}

export default Menu;
