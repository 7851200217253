/*
*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  FormGroup,
  Button,
  Row,
  Col,
} from 'reactstrap';

import { reduxForm, Field } from 'redux-form';
import { createTextMask } from 'redux-form-input-masks';

import renderField from '../../components/inputs/renderField';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/enviaFoto.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalUpload from './ModalUpload';

import config from '../../config/default';

let EnviarFotoForm = props => {
  const { handleSubmit, enviaFoto, cityId } = props;
  const [motoristaSelecionado, setMotoristaSelecionado] = useState({});
  const [podePesquisar, setPodePesquisar] = useState(false);
  const modalRef = React.createRef();
  var camposPreenchidos = [];

  const cpfMask = createTextMask({
    pattern: '999.999.999-99',
    onChange: e => vefiricaSePodePesquisarCPF(e)
  });
  const RESULTADO_MAXIMO = 5;
  const exibirModalUpload = (motorista) => {
    setMotoristaSelecionado(motorista);
    modalRef.current.toggle();
    modalRef.current.limpar();
  }

  const vefiricaSePodePesquisarCPF = (value) => {
    const valor = value.replace(/\D/g, "");
    habilitaDesabilitaPesquisa(valor, 'cpf');
  }

  const vefiricaSePodePesquisar = (e) => {
    const valor = e.target.value.replace(" ", "");
    habilitaDesabilitaPesquisa(valor, e.target.name)
  }

  const habilitaDesabilitaPesquisa = (valor, nome) => {
    if (valor.length > 0) {
      camposPreenchidos.push(nome);
    } else {
      camposPreenchidos = camposPreenchidos.filter((c) => c !== nome);
    }
    setPodePesquisar(camposPreenchidos.length > 0);
  }

  return (
    <form onSubmit={(handleSubmit)}>
      <div className="filtro-horizontal">
        <ModalUpload titulo="Importar foto" ref={modalRef} motorista={motoristaSelecionado} />
        <Row>
          <Col md={3}>
            <FormGroup>
              <Field label="Nome completo" component={renderField} name="nome" id="nome" className="form-control" onChange={vefiricaSePodePesquisar} />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Field {...cpfMask} label="CPF" component={renderField} name="cpf" id="cpf" placeholder="Digite o CPF" className="form-control" />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Field label="e-mail" component={renderField} name="email" id="email" placeholder="" className="form-control" onChange={vefiricaSePodePesquisar} />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Field label="Telefone" component={renderField} name="telefone" id="telefone" placeholder="" className="form-control" onChange={vefiricaSePodePesquisar} />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Field label="Registro Municipal" component={renderField} name="codigo" id="codigo" className="form-control" onChange={vefiricaSePodePesquisar} />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <Button size='md' type="submit" color="info" className={podePesquisar ? '' : 'botao-desabilitado'} disabled={!podePesquisar}>Pesquisar</Button>
      <div className="resultado">
        {enviaFoto && enviaFoto.motoristas && enviaFoto.motoristas.length === 0 &&
          <div style={{ color: '#888' }}><b>Nenhum motorista encontrado.</b></div>
        }
        {enviaFoto && enviaFoto.motoristas && enviaFoto.motoristas.length > RESULTADO_MAXIMO &&
          <div style={{ color: '#888' }}><b>Sua pesquisa retornou mais do que 5 resultados. Por favor refine sua pesquisa.</b></div>
        }
        {enviaFoto && enviaFoto.motoristas && enviaFoto.motoristas.length > 0 &&
          (<Row className="header">
            <Col className="title" md={3}>
              <div>Nome</div>
            </Col>
            <Col className="title" md={2}>
              <div>CPF</div>
            </Col>
            <Col className="title" md={2}>
              <div>e-mail</div>
            </Col>
            <Col className="title" md={1}>
              <div>Telefone</div>
            </Col>
            <Col className="title" md={2}>
              <div>Registro Municipal</div>
            </Col>
            <Col className="title" md={1}>
              <div>Importar                                                                                                                                                 </div>
            </Col>
          </Row>)
        }

        {enviaFoto && enviaFoto.motoristas &&
          enviaFoto.motoristas.slice(0, RESULTADO_MAXIMO).map((m) => (
            <Row className="linha" key={`motorista-${m.login}`}>
              <Col className="celula" md={3}>
                <img className="foto-motorista" src={`${config.urlFoto}/${cityId}/${m.login}.png`} alt=""/>
                <span>{m.user.fullName}</span>
              </Col>
              <Col className="celula" md={2}>
                {m.user.cpf}
              </Col>
              <Col className="celula" md={2}>
                <div>{m.user.email}</div>
              </Col>
              <Col className="celula" md={1}>
                <div>{m.user.phoneNumber}</div>
              </Col>
              <Col className="celula" md={2}>
                <div>{m.login}</div>
              </Col>
              <Col className="celula importar-action" md={1}>
                <div onClick={() => exibirModalUpload(m)}><FontAwesomeIcon icon={faCloudUploadAlt} /></div>
              </Col>
            </Row>
          ))
        }
      </div>
    </form>
  );
}

EnviarFotoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cityId: PropTypes.string.isRequired
};

EnviarFotoForm = reduxForm({
  // a unique name for the form
  form: 'enviaFoto'
})(EnviarFotoForm)

// Decorate with connect to read form values
//const selector = formValueSelector('enviarFotoForm') // <-- same as form name
EnviarFotoForm = connect(
  state => {
    // can select values individually
    // const codigo = selector(state, 'codigo');
    // const cpf = selector(state, 'cpf');
    // const nome = selector(state, 'nome');
    const { enviaFoto } = state;
    return {
      //   codigo,
      //   cpf,
      //   nome,
      enviaFoto
    }
  }
)(EnviarFotoForm)

export default EnviarFotoForm;
