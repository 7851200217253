import React from 'react';
import { Label } from "reactstrap";

// eslint-disable-next-line react/prop-types
const renderSelect = ({ input, label, className, errorLabel, placeholder, children, type, meta: { touched, error, warning } }) => (
  <>
    <Label className="label-taxi">{label}</Label>
    <div>
      <select {...input} placeholder={placeholder} type={type} className={className}>
        {children}
      </select>
      {touched && ((error && <span className="error">{`${errorLabel?errorLabel:''} ${error}`}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </>
)

export default renderSelect;