/*
*/
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import Info from '../../components/modal/Info';
import LoginForm from './LoginForm';
import {loginAction, obterCidadesAction} from '../../actions/loginActions';
import { gravarDados } from '../../utils/Session';
import '../../assets/css/login.css';
import logoBackoffice from "../../assets/img/logo-backoffice.png";

let info = React.createRef();

const problemasComSeusDados = () => (
  <div>
    <div>* Revise seus dados e tente novamente</div>
    <div>* Se o problema persistir entre em contato com o TAXI.RIO Cidades</div>
  </div>
);

const Login = () => {
  const [cities,setCities] = useState([]);
  const handleSubmit = (values, dispatch, history) => {
    let body = {login: values.usuario, password:values.senha, cityId:values.cityId};
    loginAction(body).then( res => {
      if(res.payload.status === 200){
        let data = {
          'token':`Bearer ${res.payload.data.token}`, 
          'displayName':res.payload.data.displayName,
          'cityId': values.cityId,
          'city': cities.filter( c => c.cityId === values.cityId )
        };
        gravarDados(data);
        history.push("/");
      } else {
        info.current.toggle()
      }
    });
  }

  useEffect( () => {
    console.log('obtendo cidades');
    
    async function fetchData() {
      const cidades = await obterCidadesAction();
      setCities(cidades.payload.data);
    }
    fetchData();
  },[]);

  let history = useHistory();
  
  return (
    <div className="background">
      <Info ref={info} titulo="Acesso negado" >
        {
          problemasComSeusDados()
        }
      </Info>

      <Container fluid="md" className="login">
        <div>
          <img className="logo-img" src={logoBackoffice} alt="Logo Taxirio"/>
        </div>
        <LoginForm cidades={cities} onSubmit={(values, dispatch) => handleSubmit(values, dispatch, history)}/>
      </Container>
    </div>
  )};

export default Login;
