/*
*/
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import Info from '../../components/modal/Info';
import ImportarTaxistaForm from './ImportarTaxistaForm';
import { validateImportFileAction, importFileAction } from '../../actions/ImportarTaxistaActions';

import {
  Row,
  Col,
  Container
} from 'reactstrap';

import { obterDados } from '../../utils/Session';

let info = React.createRef();

const problemasComSeusDados = () => (
  <div>
    <div>* Revise seus dados e tente novamente</div>
    <div>* Se o problema persistir entre em contato com o TAXI.RIO Cidades</div>
  </div>
);

const naoHabilitado = () => (
  <div>
    <div>* Entre em contato com sua Prefeitura e solicite a sua inclusão no serviço.</div>
    <div>* Dúvidas? Entre em contato com o TAXI.RIO Cidades</div>
  </div>
);

const ImportarTaxista = () => {

  const handleSubmit = (values, dispatch) => {
    console.log(values);
    if (JSON.stringify(values) === '{}') return;

    values.cityId = obterDados().cityId;
    dispatch(importFileAction(values));

  }

  const importarArquivo = (arquivo) => {
    const formData = new FormData();
    formData.append('cityId', obterDados().cityId);
    formData.append('arquivo', arquivo, arquivo.name);
    importFileAction(formData);
  }

  const verificarArquivo = (arquivo) => {
    console.log(arquivo);
    const formData = new FormData();
    formData.append('cityId', obterDados().cityId);
    formData.append('arquivo', arquivo, arquivo.name);
    return validateImportFileAction(formData);
  }

  const [mensagem] = useState({ titulo: '', opcao: 0 });
  let history = useHistory();
  let session = obterDados();
  if (!session) {
    history.push("/");
  }
  return (
    <>
      <Info ref={info} titulo={mensagem.titulo} >
        {
          (mensagem.opcao === 1 && problemasComSeusDados()) ||
          (mensagem.opcao === 2 && naoHabilitado())
        }
      </Info>

      <Container fluid className="home-container">
        <Row>
          <Col xs={12} md={12} lg={12}>
            <ImportarTaxistaForm onSubmit={(values, dispatch) => handleSubmit(values, dispatch)} verificarArquivo={(arquivo) => verificarArquivo(arquivo)} importarArquivo={(arquivo) => importarArquivo(arquivo)} />
          </Col>
        </Row>
      </Container>

    </>
  )
};

export default ImportarTaxista;
