import * as types from './actionConstants';
import apiRequest from '../utils/ApiRequest';
import {obterDados} from '../utils/Session';

export const findDriversAction = async (value) => {
  let resultado;
  
  let config = {
    headers: { "Authorization": obterDados().token }
  }
  
  
  await apiRequest().post('/drivers/find', value, config).then( response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  });
  
  return ({
    type: types.IMPORTAR_FOTO_BUSCAR_TAXISTA,
    payload: resultado
  });
}


export const uploadFotoAction = async (value) => {
  let resultado;
  
  let config = {
    headers: {
      Authorization: obterDados().token,
      'content-type': 'multipart/form-data'
    }
  }
  
  await apiRequest().post('/drivers/uploadPhoto', value, config).then( response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  });
  
  return ({
    payload: resultado
  });
}


export const validateImportFileAction = async (value) => {
  let resultado;
  
  let config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  
  await apiRequest().post('/drivers/import/validateFile', value, config).then( response => {
    resultado = response;
  }).catch(error => {
    resultado = error.response;
  });
  
  return ({
    payload: resultado
  });
}
