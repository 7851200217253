/*
*/
import React from "react";
import { isAuthenticated } from '../services/security';
import { useHistory } from 'react-router-dom';

export const Home = () => {
  const history = useHistory();
  if( !isAuthenticated() ) {
    history.push("/login");
  }
  return (
    <>

    </>
  );
}

