import React from 'react';
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import {uploadFotoAction} from '../../actions/EnviarFotoActions';
import { obterDados } from '../../utils/Session';

import Info from '../../components/modal/Info';

let info = React.createRef();

const cropper = React.createRef();  

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

class Dialog extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      modal:false, 
      src:'', 
      file:undefined,
      infoModal:{
        titulo:'', 
        mensagem:''
      }
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  
  toggle () {
    const {modal} = this.state;
    this.setState({...this.state, modal:!modal});
  }

  limpar = () => {
    this.setState({src:'', file:undefined});
  }

  _onChange(e) {
    this.setState({...this.state, file:e.target.files[0],src: e.target.files[0]});
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { motorista } = this.props;
    const formData = new FormData();
    formData.append('login', motorista.login);
    formData.append('cityId', obterDados().cityId);
    formData.append('foto',dataURLtoBlob(cropper.current.getCroppedCanvas().toDataURL('image/png')));
    uploadFotoAction(formData).then(res => {
      const { message } = res.payload.data;
      const { status } = res.payload.request;
      
      let infoModal = { mensagem: message};
      if(status === 200) {
        infoModal.titulo = "Informação";
        this.toggle();
      } else {
        infoModal.titulo = "Erro";
      }
      this.setState({...this.state, infoModal });
      info.current.toggle();
    });
  }

  onChange(e) {
    const tiposPermitidos = ['image/png','image/jpg','image/jpeg'];
    
    e.preventDefault();
    
    this.setState({...this.state, file:e.target.files[0]});
    
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    //console.log(files[0].type, tiposPermitidos.findIndex(tp => files[0].type === tp));
    if(tiposPermitidos.findIndex(tp => files[0].type === tp) < 0){
      e.target.value = null;
      let infoModal = { titulo: "Erro", mensagem: "Tipo de arquivo não permitido"};
      this.setState({...this.state, infoModal });
      info.current.toggle();
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({...this.state, src: reader.result });
    };
    reader.readAsDataURL(files[0]);
  }
  
  _crop (){
    // image in dataUrl
    //console.log(cropper);
    // console.log(cropper.current.getCroppedCanvas().toDataURL());
  }

  render() {
    const {
      titulo,
    } = this.props;

    const { modal, infoModal } = this.state;

    return (
      <div>
        <Info ref={info} titulo={infoModal.titulo} >
          { infoModal.mensagem }
        </Info>
        <Modal isOpen={modal} toggle={()=>this.toggle()} style={{width:'1000px'}}>
          <ModalHeader toggle={()=>this.toggle()}>{titulo} </ModalHeader>
          <ModalBody>
            <form onSubmit={this.onFormSubmit}>      
              <input type="file" name="myImage" onChange={this.onChange.bind(this)} />
              <br />
              <div style={{fontSize:'0.8em'}}>Arquivo deve ter o formato .PNG, .JPG ou .JPEG</div>
              <Cropper
                ref={cropper}
                src={this.state.src}
                style={{height: 300, width: '100%',marginBottom:'10px'}}
                // Cropper.js options
                aspectRatio={1 / 1}
                guides={false}
                crop={this._crop.bind(this)} />

              <Button color="success" disabled={this.state.src === ''} onClick={this.onFormSubmit} type="button">Importar</Button>{' '}
              <Button color="danger"  onClick={()=>this.toggle()}>Cancelar</Button>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

Dialog.propTypes = {
  titulo: PropTypes.string.isRequired,
  motorista: PropTypes.object.isRequired
};

export default Dialog;