/*
*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  FormGroup,
  Button,
  Row,
  Col,
} from 'reactstrap';

import { reduxForm, Field } from 'redux-form';
import { createTextMask } from 'redux-form-input-masks';

import renderField from '../../components/inputs/renderField';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/enviaFoto.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalUpload from './ModalUpload';

let ImportarTaxistaForm = props => {
  const { handleSubmit, importarArquivo, verificarArquivo, enviaFoto } = props;

  const modalRef = React.createRef();

  const [arquivo, setArquivo] = useState({});
  const [verificado, setVerificado] = useState(false);
  const [erros, setErros] = useState([]);

  const onFileChange = event => {
    setArquivo(event.target.files[0]);
    setVerificado(false);
  };

  const handleClickVerificarArquivo = () => {
    verificarArquivo(arquivo).then(r => {
      if (r && r.payload) {
        const { resultado } = r.payload.data;
        console.log(resultado)
        if (resultado) {
          if (resultado.erros.length === 0) {
            setVerificado(true);
          } else {
            setErros(resultado.erros);
          }
        }
      } else {
        alert('Erro ao tentar verificar o arquivo, tente novamente');
      }
    })
  }

  return (
    <form onSubmit={(handleSubmit)}>
      <div className="filtro-horizontal">
        <Row>
          <Col md={3}>
            <FormGroup>
              <input type="file" onChange={onFileChange} />
            </FormGroup>
          </Col>
        </Row>
      </div>
      {verificado ?
        <Button size='md' onClick={() => importarArquivo(arquivo)} color="info">Importar</Button> :
        <Button size='md' color="info" onClick={handleClickVerificarArquivo}>Verificar</Button>
      }

      {erros.map(e => (
        <Row key={`erro-linha-${e.linha}`}>
          <Col md={2}>
            {`Linha: ${e.linha}`}
          </Col>
          <Col md={6}>
            {`Mensagem: ${e.erro}`}
          </Col>
        </Row>
      ))}

    </form>
  );
}

ImportarTaxistaForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

ImportarTaxistaForm = reduxForm({
  // a unique name for the form
  form: 'enviaFoto'
})(ImportarTaxistaForm)

// Decorate with connect to read form values
//const selector = formValueSelector('enviarFotoForm') // <-- same as form name
ImportarTaxistaForm = connect(
  state => {
    // can select values individually
    // const codigo = selector(state, 'codigo');
    // const cpf = selector(state, 'cpf');
    // const nome = selector(state, 'nome');
    const { enviaFoto } = state;
    return {
      //   codigo,
      //   cpf,
      //   nome,
      enviaFoto
    }
  }
)(ImportarTaxistaForm)

export default ImportarTaxistaForm;
