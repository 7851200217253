import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { EnviaFotoReducer } from './EnviaFotoReducer';
// import { ComplementarDadosReducer } from './complementarDadosReducer';

export const Reducers = combineReducers({
  form: formReducer,
  enviaFoto: EnviaFotoReducer
  // primeiroAcesso: PrimeiroAcessoReducer,
  // complementarDados: ComplementarDadosReducer,
});