/*!
*/
import React from "react";
// reactstrap components
import {
  Navbar,
  Container
} from "reactstrap";

import { obterDados, logout } from '../../utils/Session'
import Menu from '../menu';

const sair = () => {
  logout();
  window.location.href = '/';
}

class TaxirioNavbar extends React.Component {
  
  render() {
    const { displayName= "" } = obterDados()|| {};
    return (
      <>
        <Navbar className="navbar-top navbar-dark navbar-static-top navbar-taxirio" expand="md" id="navbar-main">
          <Container fluid={true} className="container-fluid-taxirio">
            <div className="navbar-header navbar-left">
              <a className="navbar-brand-taxirio" href="/" ><img width="120" src={require("../../assets/img/logo-backoffice.png")} alt="Logo Taxirio"/></a>    
              <div className="navbar-orgao d-none d-sm-block">
                <p className="text-white">Prefeitura da Cidade do Rio de Janeiro</p>
                <p className="text-white">Iplanrio - Empresa Municipal de Informática</p>
              </div>
            </div>
            <ul className="nav navbar-nav navbar-right navbar-prefeitura">
              <li><img src={require("../../assets/img/logoPrefeitura_branco.png")} alt="logo Prefeitura do rio de janeiro" width="100"/></li>
            </ul>
          </Container>
          <Container fluid={true} className="container-fluid-taxirio">
            <div className="info-user-box">
              <div className="info-user">{displayName}</div>
              <div className="info-user-sair" onClick={() => sair()}>Sair</div>
            </div>          
          </Container>
        </Navbar>
        <Menu />
        <br/>
        <br/>
      </>
    );
  }
}

export default TaxirioNavbar;
